var Utils = require("./utils");
var queryArray = Utils.queryArray;

function startRotator() {
  var activeSlide = document.querySelector("section.hero .hero-rotator.active");
  var nextSlide = activeSlide.nextElementSibling;

  if (nextSlide) {
  } else {
    nextSlide = queryArray("section.hero .hero-rotator")[0];
  }

  var slides = queryArray("section.hero .hero-rotator");
  slides.forEach(function(item) {
    item.classList.remove("inactive");
  });
  activeSlide.classList.add("inactive");
  activeSlide.classList.remove("active");
  nextSlide.classList.add("active");
}

// Make initial one active
queryArray(".hero-rotator")[0].classList.add("active");
queryArray(".hero-rotator")[0].classList.add("first");

setTimeout(function() {
  queryArray(".hero-rotator")[0].classList.remove("first");
}, 16000);

var rotator = setInterval(startRotator, 8000);
document
  .querySelector("section.hero")
  .addEventListener("mouseover", function() {
    clearInterval(rotator);
  });
document
  .querySelector("section.hero")
  .addEventListener("mouseleave", function() {
    rotator = setInterval(startRotator, 8000);
  });