var Utils = {
  queryArray: function(selector, scope) {
    scope = scope || document.body;
    return Array.prototype.slice.call(scope.querySelectorAll(selector));
  },
  ready: function(func) {
    document.readyState !== "loading" ? func() : document.addEventListener("DOMContentLoaded", func)
  }
}

Utils.touch = {
  isSupported: "ontouchstart" in window || navigator.maxTouchPoints,
  isDragging: false
};

Utils.ready(function() {
  document.body.addEventListener("touchmove", function() {
    Utils.touch.isDragging = true;
  });
  document.body.addEventListener("touchstart", function() {
    Utils.touch.isDragging = false;
  });
});

Utils.supports = {
  pointerEvents: function() {
    var style = document.createElement("a").style;
    style.cssText = "pointer-events:auto";
    return style.pointerEvents === "auto";
  }()
};

module.exports = Utils;